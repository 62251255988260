<template>
    <div>
        <st-modal
            id="st-location-details-form"
            hide-header-delimiter
            hide-footer-delimiter
            size="xl"
            hideable
            ref="st-location-details-form"
            customClass="form-modal"
        >
            <template #header>
                <p class="font-size-h5 font-weight-bold mb-4 pb-0">{{$t('CONTROL.PREPARATION.FORM.MODAL_HEADER')}}</p>
            </template>
            <template #body>
                <location-details-form
                    ref="location-details-form"
                    @cancelForm="hide"
                    @updateLocation="updateLocation"
                />
            </template>
            <template #footer>
                <div class="d-flex justify-content-between w-100 pt-0">
                    <st-button
                        variant="link"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="hide"
                    > {{ $t('GENERAL.BUTTON.CANCEL') }}
                    </st-button>
                    <st-button
                        variant="primary"
                        class="font-size-h6 px-5 py-3 my-3 mr-3"
                        :callback="doSave"
                    >
                        <span>{{ $t('GENERAL.BUTTON.SAVE') }}</span>
                    </st-button>
                </div>
            </template>
        </st-modal>
    </div>
</template>
<script>
import LocationDetailsForm from './LocationDetailsForm.vue';

export default {
    name: 'LocationDetailsModal',
    components: { LocationDetailsForm },
    data() {
        return {
           locationModalOpen: false
        };
    },
    methods: {
        show() {
            this.$refs['st-location-details-form'].show();
        },
        hide() {
            this.$refs['st-location-details-form'].hide();
            this.$emit('locationModalOpen', false);
        },
        doSave() {
            this.$refs['location-details-form'].submit();
        },
        updateLocation(data) {
            this.hide();
            this.$emit('updateLocation', {location: data, locationModalOpen: false});
        }
    },
}
</script>
