<template>
    <form class="form"
        novalidate="novalidate"
        id="st-modal-location-details-form"
        @submit.stop.prevent="onSubmit()"
    >
        <div class="row mb-2">
            <div class="col-6">
                <div class="form-group">
                    <label class="font-size-h6"> {{fields.territorial_administrative_unit_id.label}} </label>
                    <st-autocomplete
                        v-model="model[fields.territorial_administrative_unit_id.name]"
                        :options="fields.territorial_administrative_unit_id.options"
                        :ref="fields.territorial_administrative_unit_id.name"
                        :name="fields.territorial_administrative_unit_id.name"
                        @change="onChange(fields.territorial_administrative_unit_id)"
                    ></st-autocomplete>
                </div>
            </div>
        </div>
        <location-map
            :viewMode="viewMode"
            :address="address"
            :location="location"
            @selectLocation="selectLocation"
        />
    </form>
</template>

<script>
import { FormSchema } from '@/shared/form/form-schema';
import { createFormValidation } from '@/shared/utils/create-form-validation';
import { ControlModel } from '@/modules/control/control-model';
import LocationMap from "@/modules/complain/components/LocationMap.vue";
const { fields } = ControlModel;
const formSchema = new FormSchema([
    fields.territorial_administrative_unit_id,
]);

export default {
    name: 'LocationDetailsForm',
    props: {},
     components: {
        LocationMap,
    },
    data() {
        return {
            fields,
            rules: formSchema.rules(),
            model:{},
            viewMode: false,
            location: null,
            address: '',
        };
    },
    created() {
        this.model = formSchema.initialValues({});
    },
    mounted() {
        this.fv = createFormValidation('st-modal-location-details-form', this.rules);
    },
    methods: {
        onChange(field) {
            this.fv.revalidateField(field.name, true);
        },
        async submit() {
            if (!this.location) {
                this.$alert({
                    type: 'warning',
                    title: this.$t('COMPLAIN.NOTIFIERS.LOCATION_TITLE'),
                    text: this.$t('COMPLAIN.NOTIFIERS.LOCATION_TEXT'),
                    showConfirmButton: false,
                });
                return;
            }
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const localities = this.fields.territorial_administrative_unit_id.options;
                const selectedLocality = localities.find( locality => locality.value === this.model.territorial_administrative_unit_id);
                this.$emit('updateLocation', {selectedLocality, ...this.location});
            };
        },
        selectLocation(data) {
            const location = {
                latitude: data.location.latitude,
                longitude: data.location.longitude
            }
            this.location = {
                adress: data.address,
                locality_name: data.attributes.City,
                location
            }
        }
    }
};
</script>
