<template>
    <div class="st-control-preparation">
        <b-card class="st-section mt-6">
            <div class="st-section-header">
                <p>{{$t('CONTROL.PREPARATION.HEADER')}}</p>
            </div>
            <div class="st-section-body mb-4">
                <div class="row">
                    <div class="col-4">
                        <div class="form-group ">
                            <label class="font-size-h6">{{$t('CONTROL.FIELDS.COMPLAIN_NUMBER')}}</label>
                            <div class="d-flex justify-content-start mt-2">
                                <st-autocomplete
                                    v-model="selectedComplain"
                                    :options="complains"
                                    ref="selectedComplain"
                                    name="selectedComplain"
                                    class="flex-grow-1"
                                    @change="onChangeComplain(selectedComplain)"
                                ></st-autocomplete>
                                <st-button
                                    variant="outline-primary"
                                    :callback="addComplain"
                                    class="ml-3 "
                                >
                                    <i class="fa fa-plus"></i>
                                    {{$t('CONTROL.BUTTON.ADD_COMPLAIN')}}
                                </st-button>
                            </div>
                        </div>
                    </div>
                    <div class="col-8">
                        <label class="font-size-h6">{{$t('CONTROL.PREPARATION.COMPLAIN_LIST_TAGS')}}</label>
                        <b-form-tags v-model="complainTags" no-outer-focus class="pb-2">
                            <template v-slot="{ tags, inputAttrs, inputHandlers, tagVariant, addTag, removeTag }">
                                <div class="d-inline-block">
                                    <b-form-tag
                                        v-for="tag in tags"
                                        @remove="removeTag(tag)"
                                        :key="tag"
                                        :title="tag"
                                        :variant="tagVariant"
                                        class="mr-1 custom-b-form-tag"
                                    >{{ tag }}</b-form-tag>
                                </div>
                            </template>
                        </b-form-tags>
                    </div>
                </div>
            </div>
        </b-card>
        <div class="d-flex justify-content-end">
            <st-button
                variant="primary"
                class="px-5 py-3 my-3 mr-3 mt-6"
                :callback="addLocation"
            >
                <span>
                    <i class="fas fa-plus"></i>
                    {{$t('CONTROL.BUTTON.ADD_LOCATION')}}
                </span>
            </st-button>
        </div>
        <div v-if="!locationFormList.length > 0">
            <b-card class="st-section mt-6">
                <div class="d-flex align-items-center">
                    <div class="bg-light-light px-4 py-4 rounded-xl mr-6 mb-2">
                        <span class="svg-icon svg-icon-3x svg-icon-warning my-2">
                            <span><i class="fas fa-home font-size-h2" ></i></span>
                        </span>
                    </div>
                    <p class="font-size-h6">
                        {{$t('CONTROL.PREPARATION.WARNING')}}
                    </p>
                </div>
            </b-card>
        </div>
        <div v-else class="mb-4">
            <h5>{{$t('CONTROL.PREPARATION.LIST')}}</h5>
        </div>
        <div>
            <template v-for="(item, id) in locationFormList" >
                <div :key=item.form :id="`st_location_section_${id}`">
                    <LocationForm
                        :id="id"
                        :item="item"
                        ref="locationForm"
                        @removeLocation="removeLocation"
                    />
                </div>
            </template>
        </div>
        <b-card class="st-section mt-6">
            <div class="st-section-header">
                <div class="st-section-header-title">
                    {{$t('CONTROL.PREPARATION.PREVIEW_MAP')}}
                </div>
            </div>
            <div class="st-section-body">
                <location-map
                    v-if="!isModalOpen"
                    :viewMode="viewMode"
                    :address="address"
                    :location="location"
                    :locations="locationFormList"
                />
            </div>
        </b-card>
        <div class="d-flex mt-6 justify-content-end">
            <st-button
                variant="primary"
                :callback="nextStep"
            >
                {{$t('CONTROL.BUTTON.NEXT_STEP')}}
            </st-button>
        </div>
        <location-details-modal
            @locationModalOpen="locationModalOpen"
            @updateLocation="updateLocationMap"
            ref="locationDetailsModal"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationMap from "@/modules/complain/components/LocationMap.vue";
import LocationForm from "@/modules/control/components/LocationForm.vue";
import LocationDetailsModal from "./LocationDetailsModal";
export default {
    name: 'ControlPreparation',
    components: {
        LocationMap,
        LocationForm,
        LocationDetailsModal
    },
    data: () => ({
        location: null,
        address: '',
        selectedComplain: '',
        viewMode: true,
        locationFormList: [],
        isModalOpen: false,
        complains: [],
        complainTags: [],
        index: 0,
        isComplainRemovedFromLocation: false,
    }),
    computed: {
         ...mapGetters({
            rows: 'complain/list/rows',
        }),
    },
    watch: {
        complainTags(newVal, oldVal) {;
            const removed = oldVal.find(v => !newVal.includes(v));
            if(removed && removed.length > 0) {
                // remove location from list
                const complainLocationIndex = this.locationFormList
                .findIndex(location => location.complain && location.complain.identification_number === removed);

                // Remove from location list only if is removed from tags
                if (!this.isComplainRemovedFromLocation) {
                    this.locationFormList.splice(complainLocationIndex, 1);
                }
            }
        },
    },
    async created() {
        await this.doFetch({
            loadStaff: true,
            params: {
                limit: 100
            }
        })
        this.complains = this.rows.map((row) => ({
            value: row.id,
            text: row.identification_number,
            complainObj: row,
        }))
    },
    methods: {
        ...mapActions({
            doFetch: 'complain/list/doFetch',
        }),
        onChange(field) {
            this.fv.revalidateField(field.name, true);
        },
        addLocation() {
            this.isModalOpen = true;
            this.$refs.locationDetailsModal.show();
        },
        addComplain () {
            const selectedComplain = this.complains.find( complain => complain.value === this.selectedComplain);
            this.complainTags.push(selectedComplain.text)
            this.selectedComplain = '';
            this.locationFormList.push(
                {
                    form: this.index,
                    isSelfcomplain: false,
                    complain:selectedComplain.complainObj
                }
            );
            this.index++;
        },
        removeLocation(data) {
            this.locationFormList.splice(data.id, 1);
            // Remove from tags  if location is based on complain
            if (data.location.complain) {
                this.isComplainRemovedFromLocation = true;
                this.complainTags = this.complainTags
                .filter(complain => complain !== data.location.complain.identification_number);
            }
        },
        locationModalOpen(data) {
            this.isModalOpen = data;
        },
        onChangeComplain() {
            this.isComplainRemovedFromLocation = false;
        },
        nextStep() {
            //TODO: Go to next step after is implemented
            this.$emit('nextStep');
        },
        updateLocationMap(data) {
            this.locationFormList.push({
                form: this.index,
                isSelfcomplain: true,
                complain: {
                    uat: {
                        locality_name: data.location.selectedLocality.text,
                    },
                    address: data.location.adress,
                    location: data.location.location,
                    locationObj: data
                }
            });
            this.index++;
        },
    }
}
</script>
