<template>
    <div class="card card-custom mb-4 py-6 beneficiary-card">
        <div class="card-body-1 px-4">
            <div class="d-flex justify-content-between">
                <div class="mb-4">
                    <p class="font-size-h5 mt-6 mb-0 pb-0 font-weight-bold">
                        {{$t('CONTROL.PREPARATION.FORM.LOCATION')}} {{id + 1}}
                    </p>
                    <small v-if="!item.isSelfcomplain" >
                        {{$t('CONTROL.PREPARATION.FORM.COMPLAIN_DESCRIPTION')}}: {{item.complain.identification_number}}
                    </small>
                </div>
                <st-button
                    variant="link"
                    class="font-size-h6 py-3 my-3 pr-0"
                    :callbackParams=callbackParams
                    :callback="removeLocation"
                >
                    <span  class="remove-beneficiary">
                        <i class="fas fa-minus text-danger"></i>
                        {{$t('CONTROL.BUTTON.DELETE_LOCATION')}}
                    </span>
                </st-button>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="form-group d-inline">
                    <label class="font-size-h6">{{$t('CONTROL.FIELDS.TERRITORIAL_ADMINISTRATIVE_UNIT_ID')}}</label>
                    <st-input-text
                        v-model="location.uat"
                        ref="uat"
                        name="uat"
                        disabled
                    />
                </div>
            </div>
             <div class="col-4">
                 <div class="form-group d-inline">
                    <label class="font-size-h6">{{$t('CONTROL.FIELDS.ADDRESS')}}</label>
                    <st-input-text
                        v-model="location.address"
                        ref="address"
                        name="address"
                        disabled
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LocationForm',
    props: {
        id: {
            type: Number,
            required: false,
        },
        item: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            location: {
                uat: '',
                address: '',
            }
        };
    },
    computed: {
        callbackParams() {
            return { id: this.id, location: this.item ?? {}};
        },
    },
    mounted() {
        this.location.uat = !this.item.complain.isSelfcomplain ?
                            this.item.complain.uat.locality_name : this.item.selfComplain.uat.locality_name;
        this.location.address = !this.item.complain.isSelfcomplain ?
                                this.item.complain.address : this.item.selfComplain.address;
    },
    methods: {
        removeLocation(id){
            this.$emit('removeLocation', id);
        },
    }
}
</script>
