<template>
    <st-page
        :title="title"
        layout="tab"
        :backLink="backLink"
    >
        <b-tabs class="st-tabs" justified lazy v-model="activeTab">
            <b-tab v-for="tab in tabs" :key="tab.index" :disabled="tab.disabled">
                <template #title>
                    <div class="d-flex justify-content-center w-100">
                        <i :class="['fas', tab.icon]"></i>
                        <span class="text-uppercase ml-4">{{ tab.titleKey }}</span>
                    </div>
                </template>
                <component
                    :is="tab.componentType"
                    @nextStep="nextStep"
                >
                </component>
            </b-tab>
        </b-tabs>
    </st-page>
</template>

<script>
import ControlPreparation from ".././components/ControlPreparation";
export default {
    name: 'ControlForm',
    components: {
        ControlPreparation,
    },
    props: {
        id: String,
    },
    data() {
        return {
            activeTab: 0,
            tabs: [
                {
                    index: 0,
                    name: 'control-preparation',
                    titleKey: 'Pregatire control',
                    icon: 'fa-edit',
                    componentType: 'ControlPreparation',
                    disabled: false,
                },
                {
                    index: 1,
                    name: 'data-collection',
                    icon: 'fa-list',
                    titleKey: 'Colectare date',
                    disabled: true,
                },
                {
                    index: 2,
                    name: 'pvcc',
                    icon: 'fa-list',
                    titleKey: 'pvcc',
                    disabled: true,
                },
                {
                    index: 3,
                    name: 'pvcc',
                    icon: 'fa-list',
                    titleKey: 'Alte documente',
                    disabled: true,
                },
                {
                    index: 4,
                    name: 'activity',
                    icon: 'fa-list',
                    titleKey: 'Activitate',
                    disabled: true,
                },
            ],
        };
    },
    computed: {
        backLink() {
            return  { name: 'controls' } ;
        },
        title() {
            if (!this.id) return this.$t('CONTROL.FORM.NEW_CONTROL');
            // TODO: update once BE is done
            return 'Control nr: 123';
        },
    },
    async created() {
        if (this.id) {
            this.tabs[1].disabled = !this.id ;
        }
    },
    methods: {
        nextStep() {
            let currentTab = this.activeTab;
            currentTab++;
            this.tabs[currentTab].disabled = false;
            this.$nextTick(() => {
                this.activeTab = currentTab;
            })
        },
    },
}
</script>
